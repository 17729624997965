exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-qualidade-js": () => import("./../../../src/pages/qualidade.js" /* webpackChunkName: "component---src-pages-qualidade-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */)
}

